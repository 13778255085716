<template>
  <div class="blog">
    <b-card class="text-center">
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'Blog',
  }
</script>
